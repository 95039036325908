
import NavTop from "@/components/NavTop/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  components: { NavTop },
  name: "",
  setup() {
    const data = reactive({
      navShow: false,
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    let router = useRouter();
    let route = useRoute();
    watch(
      () => router,
      (newValue, oldValue) => {
        if (newValue.currentRoute.value.fullPath == "/login") {
          data.navShow = false;
        } else data.navShow = true;
      },
      { immediate: true, deep: true }
    );
    // 返回事件
    return {
      ...toRefs(data),
    };
  },
});
