
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { fa } from "element-plus/es/locale";
import MyDialog from "@/components/Dialog/index.vue";
import store from "@/store";
import { useRouter } from "vue-router";
import popover from "@/components/popover/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
  computed,
  ref,
} from "vue";
export default defineComponent({
  name: "",
  components: { MyDialog, popover },
  setup() {
    let router = useRouter();
    const data = reactive({
      logout: true,
      navList: [
        {
          id: 1,
          name: "自动化监控",
          active: true,
          show: true,
          path: "AutoMonitor",
          img: require("@/assets/img/highlight.png"),
          img2: require("@/assets/img/navleft.png"),
        },
        {
          id: 2,
          name: "生产管理",
          active: false,
          show: true,
          path: "ProductionManagement",
          img: require("@/assets/img/highlight.png"),
          img2: require("@/assets/img/navleft.png"),
        },
        {
          id: 3,
          name: "间接热成型加工生产线自动化控制系统",
          active: false,
          show: false,
        },
        {
          id: 4,
          name: "工艺监控",
          active: false,
          show: true,
          path: "Craft",
          img: require("@/assets/img/highlight2.png"),
          img2: require("@/assets/img/navright.png"),
        },
        {
          id: 5,
          name: "人员管理",
          active: false,
          show: true,
          path: "Personnel",
          img: require("@/assets/img/highlight2.png"),
          img2: require("@/assets/img/navright.png"),
        },
      ],

      navList2: [
        {
          id: 3,
          name: "间接热成型加工生产线自动化控制系统",
          active: false,
          show: false,
        },
      ],
      NavId: <any>"1",
      permissionId: <any>"",
      options: [],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      console.log(store.state.popoverShow);
      getProject();
      // 权限控制
      if (data.permissionId == 2 || data.permissionId == 3) {
        data.navList = data.navList.filter((item) => item.id == 3);
      }
      console.log(data.navList);
      //3.组件挂载到页面之后执行-------onMounted
      let Data = {
        id: data.NavId,
      };
      GetForEach(Data);
      // if (store.state.NavId != "undefined") {
      //   console.log(store.state.NavId != "undefined", store.state.NavId);
      //   let item = data.navList.filter((it: any) => it.id == store.state.NavId);
      //   console.log(item);
      //   getCut(item);
      // }

      if (sessionStorage.getItem("isiframe")) {
        data.logout = false;
      } else data.logout = true;
    });

    data.NavId = computed(() => {
      return store.state.NavId;
    });
    data.permissionId = computed(() => {
      return store.state.permissionId;
    });

    const getProject = () => {
      Api.commonPost(
        "dap",
        "project",
        { companyId: store.state.projectId },
        (res: any) => {
          // console.log(res);
          data.options = res.data.projectInfos;
        },
        (err: any) => {
          console.log(err);
          ElMessage.error(err.msg);
        }
      );
    };

    const getCut = (Data: any) => {
      if (Data.id == 3) {
        return;
      }
      sessionStorage.setItem("NavId", Data.id);
      GetForEach(Data);

      router.push({
        path: Data.path,
      });
    };
    const GetForEach = (Data: any) => {
      data.navList.forEach((item) => {
        if (item.id == 3) {
          return;
        }
        item.id == Data.id ? (item.active = true) : (item.active = false);
      });
    };
    const MyMyDialog = <any>ref();
    const getDialog = (Item: any) => {
      MyMyDialog.value.getShow(Item);
    };

    const getConfirm = () => {
      Api.commonPost(
        "User",
        "logout",
        {},
        (res: any) => {
          ElMessage({
            message: "退出登录成功",
            type: "success",
          });

          store.commit("GetNav", 1);

          router.push({
            path: "/login",
          });
        },
        (err: any) => {
          console.log(err);
          ElMessage.error(err.msg);
        }
      );

      MyMyDialog.value.getClose("close");
    };
    const getClose = () => {};
    const getSkewing = (Id: any) => {
      let name = "";
      if (Id == 2) {
        name = "imgs_left";
      } else if (Id == 5) {
        name = "imgs_right";
      }
      return name;
    };

    const changePro = () => {};
    return {
      ...toRefs(data),
      getCut,
      MyMyDialog,
      getDialog,
      getClose,
      getConfirm,
      getSkewing,
      changePro,
      store,
    };
  },
});
