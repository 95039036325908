
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    options: {
      type: Array,
    },
  },

  setup(props, ext) {
    const data = reactive({
      popoverShow: true,
      selectBool: true,
      projectId: "",
      title: "",
    });
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      if (sessionStorage.getItem("isiframe")) {
        data.popoverShow = true;
      } else data.popoverShow = false;

      getList(props?.options);
    });

    watch(
      props,
      (newValue) => {
        getList(newValue.options);
      },
      { deep: true }
    );

    const getList = (options: any) => {
      //   console.log("触发了", options, store.state.user.projectId);
      data.projectId = store.state.projectId;
      if (options.length) {
        data.title = options?.filter(
          (item: any) => item.projectId == store.state.projectId
        )[0].projectName;
      }
    };
    const changePro = (id: any) => {
      if (id == 10000) {
        return;
      }
      // ext.emit("changePro", id);
      store.commit("SET_projectId", id);

      if (id == 1000) {
        window.parent.postMessage(
          { type: "check", data: { path: "/twin-factory2" } },
          "*"
        );
      }
      if (id == 2000) {
        window.parent.postMessage(
          {
            type: "check",
            data: {
              path: "/sandblasting",
              id,
              query: {
                projectId: 2000,
                productId: 2000,
              },
            },
          },
          "*"
        );
      }
    };

    const gethome = () => {
      if (sessionStorage.getItem("isiframe")) {
        window.parent.postMessage({ type: "check", data: { path: "/" } }, "*");
      }
    };
    const getSelect = () => {
      data.selectBool = !data.selectBool;
    };
    return {
      ...toRefs(data),
      router,
      changePro,
      gethome,
      getSelect,
    };
  },
});
